export const state = {
  isFirstVisit: false,
  isLoggedIn: false,
  hasLoadedApp: false,
  jwt: null,
  isAuthenticating: true,
  authToken: null,
  errors: [],
  currentUser: null,
  connected: true,
  notifications: [],
  currentModal: null,
  currentModalMessage: null,
  signInModalOpen: false,
  redirectOnLogin: null,
  currentPage: null,
  currentCart: {},
  eventCart: {},
  guestCart: {},
  title: 'Chewbox',
  addressRef: null,
  token: null,
  route: null,
  isLoading: false,
  selectedCategories: [],
};
