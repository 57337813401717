<template>
	<div class="flex flex-col sm:flex-row space-y-3 sm:space-y-0 justify-between">
		<div v-if="!isTitle" class="flex space-x-4">
			<div class="relative">
				<Avatar size="14" :src="profile.avatar" :username="profile.name" :title="profile.name"/>
				<label for="upload-input">
					<a class="w-5 h-5 rounded-full bg-gray-400 absolute flex justify-center items-center -bottom-1 -right-1">
						<Icon name="PencilIcon" size="4" color="white"/>
					</a>
				</label>
				<input id="upload-input" style="outline: none; display: none" type="file" @change="onFileChange" accept="image/*"/>
			</div>
			<div>
				<Text size="2xl" weight="bold" color="black" :content="profile.name"/>
				<Text size="sm" weight="" color="gray-800" :content="profile.description"/>
			</div>
		</div>
    <Text v-if="isTitle" size="4xl" color="black" weight="bold" :content="title"/>
		<Dropdown :options="options" :label="label" :menu-item-clicked="item => $emit('onClick', item)"/>
	</div>
</template>

<script>
import Text from "../../../atoms/Text/Text.vue";
import Avatar from '../../../atoms/Avatar/Avatar.vue'
import Icon from '../../../atoms/Icons/Icons'
import Dropdown from "../../../molecules/Dropdown/Dropdown/Dropdown";

export default {
	components: {
		Text,
		Avatar,
		Icon,
		Dropdown
	},
	name: "ListViewItem",
	props: {
		label: {
			type: String,
			default: '',
		},
		profile: {
			type: Object,
			default: () => {
			}
		},
		options: {
			type: Array,
			default: () => []
		},
    isTitle: {
		  type: Boolean,
      default: false,
    },
    title: {
		  type: String,
      default: ''
    }

	},
	methods: {
		onFileChange: async function (ev) {
			const files = ev.target.files;
			await this.createImage(files[0]);
		},
		createImage: async function (file) {
			const reader = new FileReader();
			reader.onload = (e) => {
				this.$emit('onUpdate', e.target.result);
			};
			reader.readAsDataURL(file);
		},
	}

}
</script>
