<template>
	<nav
			@click="store.isShow = false"
			:class="`relative space-y-1 ${navCustomClass} bg-${bgColor}`"
			aria-label="Sidebar"
			:ref="`base`"
	>
		<div :class="`w-full px-2 pb-2`">
			<div :class="`flex cursor-pointer mt-4 space-x-1 h-6`" @click="() => {onBack(1); tabBgHeight = 0; tabBgWidth = 0; tabBgTop = 0;}" >
				<Icon v-if="activeIndex === 1" name="ArrowNarrowLeftIcon" color="white"/>
				<Text v-if="activeIndex === 1" content="Dashboard" color="white" />
			</div>
		</div>
		<span :class="`absolute z-0 rounded-md transition-all duration-300 bg-${selectionBgColor}`"
					:style="{top: tabBgTop + 'px', width: tabBgWidth + 'px', height: tabBgHeight + `px`}"></span>
		<swiper v-if="showSwiper" @swiper="setSwiper" :simulateTouch="false" :modules="[Controller]"
						:controller="{ control: swiper }" :slides-per-view="1"
						:spaceBetween="10" :initialSlide="activeIndex && parseInt(activeIndex)" class='w-full'>
			<swiper-slide class='h-full'>
				<a
						v-for="item in navigation"
						:ref="item.name"
						:key="item.name"
						:href="item.href"
						:class="[`hover:bg-${hoverBg} hover:bg-${hoverBgOpacity}`, ' rounded-md relative flex items-center px-3 py-2']"
						@click.prevent="() => {
							menuItemClicked(item);
							selectedId=item.id;
							if (item.children) {
								subnavigations = item.children
								onNext(0)
								this.tabBgTop = 0;
								this.tabBgWidth = 0;
								this.tabBgHeight = 0;
							} else {
								getPos(item.name);
								store.isShow=false;
							}
						}"
				>
					<Icon
							:name="item.icon"
							is-outline
							:color="selected && selectedId === item.id ? iconSelectionColor : iconColor"
							size="6"
							class="flex-shrink-0 mr-3 -ml-1"
					/>
					<Text
							size="sm"
							weight="medium"
							:color="selected && selectedId === item.id ? textSelectionColor : textColor"
							:content="item.name"
							:custom-class="`truncate hover:text-${hoverTextColor}`"
					/>
					<Badge v-if="item.count" :isRounded="true" class="inline-block ml-auto" px="3" py="0.5" size="xs"
								 weight="medium" :bgColor="item.current ? badgeSelectionColor : badgeColor"
								 :textColor="item.current ? 'white' : 'white'" :label="item.count"/>
				</a>
			</swiper-slide>
			<swiper-slide class='h-full'>
				<a
						v-for="item in subnavigations"
						:ref="item.name"
						:key="item.name"
						:href="item.href"
						:class="[`hover:bg-${hoverBg} hover:bg-${hoverBgOpacity}`, ' rounded-md relative flex items-center px-3 py-2']"
						@click.prevent="() => {
							menuItemClicked(item);
							selectedId=item.id;
							getPos(item.name);
							store.isShow=false;
						}"
				>
					<Icon
							:name="item.icon"
							is-outline
							:color="selected && selectedId === item.id ? iconSelectionColor : iconColor"
							size="6"
							class="flex-shrink-0 mr-3 -ml-1"
					/>
					<Text
							size="sm"
							weight="medium"
							:color="selected && selectedId === item.id ? textSelectionColor : textColor"
							:content="item.name"
							:custom-class="`truncate hover:text-${hoverTextColor}`"
					/>
					<Badge v-if="item.count" :isRounded="true" class="inline-block ml-auto" px="3" py="0.5" size="xs"
								 weight="medium" :bgColor="item.current ? badgeSelectionColor : badgeColor"
								 :textColor="item.current ? 'white' : 'white'" :label="item.count"/>
				</a>
			</swiper-slide>
		</swiper>

	</nav>
</template>

<script>
import {computed, ref} from "vue";
import {store} from "../../../store.js";
import Icon from "../../../atoms/Icons/Icons.vue";
import Text from "../../../atoms/Text/Text.vue";
import Badge from "../../../molecules/Badge/Basic/Basic.vue";
import {Swiper, SwiperSlide} from 'swiper/vue/swiper-vue';
import {Controller} from 'swiper';
import 'swiper/swiper.min.css';

/**
 * - Use it to show a vertical navigation with icons and badges
 */
export default {
	components: {
		Icon,
		Text,
		Badge,
		Swiper,
		SwiperSlide,
	},
	props: {
		/**
		 * use it for assigning background color for navigation
		 */
		bgColor: {
			type: String,
			default: "white",
		},
		/**
		 * use it for assigning background color for an item
		 */
		selectionBgColor: {
			type: String,
			default: "gray-100",
		},
		/**
		 * use it for assigning background color for an item on hover
		 */
		hoverBg: {
			type: String,
			default: "gray-50",
		},
		/**
		 * use it for assigning background color for an item on hover
		 */
		hoverBgOpacity: {
			type: String,
			default: "",
		},
		/**
		 * use it for assigning text color on hover
		 */
		hoverTextColor: {
			type: String,
			default: "gray-900",
		},
		/**
		 * use it for assigning icon color
		 */
		iconColor: {
			type: String,
			default: "gray-400",
		},
		/**
		 * use it for assigning text color
		 */
		textColor: {
			type: String,
			default: "gray-600",
		},
		/**
		 * use it for assigning badge color
		 */
		badgeColor: {
			type: String,
			default: "gray-200",
		},
		/**
		 * use it for assigning icon color of current selection
		 */
		iconSelectionColor: {
			type: String,
			default: "gray-500",
		},
		/**
		 * use it for assigning text color of current selection
		 */
		textSelectionColor: {
			type: String,
			default: "gray-900",
		},
		/**
		 * use it for assigning badge color of current selection
		 */
		badgeSelectionColor: {
			type: String,
			default: "gray-50",
		},
		/**
		 * use it for navigation items
		 */
		navigation: {
			type: Array,
			default: () => [],
		},
		/**
		 * action on clicking an item
		 */
		itemClicked: {
			type: Function,
			default: () => {
			},
		},
		/**
		 * active item
		 */
		selectedItem: {
			type: Object,
			default: null,
		},
		/**
		 * custom clas for nav
		 */
		navCustomClass: {
			type: String,
			default: "",
		},
		isShow: {
			type: Boolean,
			default: true,
		}
	},

	data() {
		const swiper = ref(null);
		const setSwiper = (e) => {
			swiper.value = e;
		};
		return {
			store,
			selectedId: this.selectedItem.id,
			tabBgTop: 0,
			tabBgWidth: 0,
			tabBgHeight: 0,
			setSwiper,
			Controller,
			swiper,
			activeIndex: 0,
			showSwiper: true,
			subnavigations: []
		}
	},

	methods: {
		getPos(el) {
			const baseY = this.$refs['base'].getBoundingClientRect().top;
			const top = this.$refs[`${el}`][0].getBoundingClientRect().top;
			const width = this.$refs[`${el}`][0].getBoundingClientRect().width;
			const height = this.$refs[`${el}`][0].getBoundingClientRect().height;
			this.tabBgTop = top - baseY -3.5;
			this.tabBgWidth = width;
			this.tabBgHeight = height;
		},
		onNext(index) {
			this.swiper.slideTo(index + 1);
			this.activeIndex = index + 1;
		},
		onBack(index) {
			this.swiper.slideTo(index - 1);
			this.activeIndex = index - 1;
		},
	},


	setup(props, {emit}) {
		const selected = computed({
			get() {
				return props.selectedItem;
			},
			set(newValue) {
				emit("update:selectedItem", newValue);
			},
		});
		const menuItemClicked = (item) => {
			emit("update:selectedItem", item);
			props.itemClicked(item);

		};
		return {
			selected,
			menuItemClicked,
		};
	},
	watch: {
		selectedItem: function (val) {
			console.log(val, 'val')
		}
	},
	mounted() {
		setTimeout(() => {
			// this.$refs.Details[0].click();
			this.getPos(this.selectedItem.name)
		}, 100);
	},
};
</script>
