<template>
  <div v-if="$route.meta.requiresAuth && $route.name !== 'Verify Email Step' && $route.name !== 'Verify Email' && $route.name !== 'Demographic Information' && $route.name !== 'Interest And Activity'" class="flex flex-col md:flex-row bg-gray-50 max-h-screen">
    <div class="h-full">
      <SideNavigationBar :title="sideNavigationBar.title" :user="{avatar: state.currentUser?.avatar, name: state.currentUser?.fullName, role: get(state.currentUser, 'groups[0].name', '')}"
                         :logo="sideNavigationBar.logo" :navigation="[
                         		{id:1,name: 'Dashboard',icon: 'HomeIcon', href: '/'},
														{id:2,name: 'Ideas / Ventures',icon: 'BookOpenIcon', href: '/ventures'},
														{id:3,name: state.currentUser?.company?.name, icon: 'AcademicCapIcon', href: '/contacts', children: [
															{id:1,name: 'Range', count: '42',icon: 'HomeIcon', href: '/'},
															{id:2,name: 'Target Customers',count: '4', icon: 'BookOpenIcon', href: '/target-customers'},
															{id:3,name: 'Contacts', icon: 'UsersIcon', href: '/contacts'},
															{id:4,name: 'Opportunities', count: '12',icon: 'ExclamationIcon', href: '/opportunities'},
															{id:5,name: 'Survey Data', icon: 'MenuIcon', href: '/survey-data'},
															{id:6,name: 'Resources', count: '7', icon: 'LinkIcon', href: '/resources'},
															{id:7,name: 'Settings', icon: 'CogIcon', href: '/settings'},
														]},
														{id:4,name: 'Resources', icon: 'LinkIcon', href: '/resources'},
                         ]"
                         :navSelectedItem="selectedItem"
                         :navIconColor="sideNavigationBar.navIconColor"
                         :navBgColor="sideNavigationBar.navBgColor" :navTextColor="sideNavigationBar.navTextColor"
                         :navTextSelectionColor="sideNavigationBar.navTextSelectionColor"
                         :navIconSelectionColor="sideNavigationBar.navIconSelectionColor"
                         :navHoverBg="sideNavigationBar.navHoverBg"
                         :navHoverBgOpacity="sideNavigationBar.navHoverBgOpacity"
                         :navSelectionBgColor="sideNavigationBar.navSelectionBgColor"
                         :navHoverTextColor="sideNavigationBar.navHoverTextColor"
                         :profileTopBorderColor="sideNavigationBar.profileTopBorderColor"
                         :profileTitleTextColor="sideNavigationBar.profileTitleTextColor"
                         :profileSubtitleTextColor="sideNavigationBar.profileSubtitleTextColor"
                         :profileSectionCustomClass="sideNavigationBar.profileSectionCustomClass"
                         :badgeColor="sideNavigationBar.badgeColor"
                         :nav-item-clicked="(item) => {$router.push(item.href)}"
												 @onClickProfile="() => $router.push('/settings')"
                         :badgeSelectionColor="sideNavigationBar.badgeSelectionColor"/>
    </div>
    <div class="w-full h-screen overflow-y-auto md:ml-80 px-4 sm:px-0">
      <slot/>
    </div>
  </div>
  <div v-else class="w-screen">
    <slot/>
  </div>
  <Alert
      :type="state.alert.type"
      :message="state.alert.message"
      :visible="state.alert.visible"
      :title="state.alert.title"
  />
</template>

<script>
import { c } from "@/components/constants.js";
import SmartBanner from "smart-app-banner";
import "smart-app-banner/dist/smart-app-banner.css";
import Alert from "@/components/common/Alert";
import SideNavigationBar from "@/components/organisms/SideNavigationBar/SideNavigationBar";
import {get} from 'lodash'
export default {
  name: "Default",
  components: {
  	Alert, SideNavigationBar
  },
  data() {
    return {
      sideNavigationBar: c.sideNavigationBar,
      selectedItem: c.sideNavigationBar.navigation.find(n => n.name === this.$route.name) || {},
      get,
    };
  },
  watch: {
    initialAddress: async function (val) {
      await this.searchGooglePlaces(val);
    },
    $route: function () {
      this.selectedItem = c.sideNavigationBar.navigation.find(n => n.name === this.$route.name) || {}
      // document.getElementById('container').scrollIntoView();
    },
  },
  methods: {
  },
  created() {
    // new SmartBanner({
    //   daysHidden: 15,
    //   daysReminder: 90,
    //   appStoreLanguage: "us",
    //   title: "Chewbox",
    //   author: "Chewbox, Inc.",
    //   button: "VIEW",
    //   store: {
    //     ios: "https://apps.apple.com/us/app/chewbox/id1453895499",
    //     android:
    //       "https://play.google.com/store/apps/details?id=com.chewbox&hl=en_US&gl=US",
    //   },
    //   price: {
    //     ios: "FREE",
    //     android: "FREE",
    //   },
    // });
  },
};
</script>

<style scoped>
.content {
  padding: 0 15px;
  min-height: 84vh;
}
</style>
