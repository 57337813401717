import gql from 'graphql-tag';
import {PaymentMethod} from "@/store/effects/gql/user/fragments";

export const companyFragment = gql`
{
   id name username slug entityType url avatar type description
   metadata
   owner {id username fullName email paymentMethods${PaymentMethod} groups{id name type}}
   employees {
     id user{
        id
        email
        firstName 
        lastName 
        fullName 
        avatar
        groups{id} 
        favoriteMedias{id name type} 
        storylines{id} 
        nominations{id}
        userGroups{
          id
          group{id name type}
          subgroups{id group{id name}}
          createdAt
          updatedAt
        }
     }
     title {id name}
     startDate
   }
   locations {
          id name nameLower
          products {
            id name nameLower
          }
        }
   groups {id name type groups{id name} company{id username name} createdAt}
   addresses {id name type address address2 city state postalCode country websites {id url name description}}
   storylines {id name dueDate progress status}
   media {id name type description avatar source gallery {id name type avatar}}
   productSubscriptions{id name description}
   deliverables{id linkedMedias{id approval media{id avatar source name}}}
   transactions {
    id paymentMethodToken amountPaid transactionId walletAmount response status note type createdAt
    storylines{id name status}
   }
}
`
