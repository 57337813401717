import {keyBy, isEmpty} from 'lodash';

/*
*
*/
export const getVentures = async ({state, effects}, data) => {
  console.log('action getVentures...');

  try {

    let options = {};

    if (isEmpty(data)) {
      options = {
        first: state.venture.venturePerPage,
        skip: (state.venture.activePage - 1) * state.venture.venturePerPage,
      };
    } else {
      options = data;
      if (!data.first) {
        options.first = state.venture.venturePerPage;
      }
      if (!data.skip) {
        options.skip = (state.venture.activePage - 1) * state.venture.venturePerPage;
      }
    }

    console.log(options, 'getVentures options');

    const {ventures} = await effects.gql.queries.ventures(options);

    console.log(ventures, 'getVentures results');
    if (data?.getValues) {
      return ventures
    } else {
      state.venture.ventures = keyBy(ventures, 'id');
    }
  } catch (e) {
    console.log(e, 'getVentures errors');
  }
};

/*
*
*/
export const saveVenture = async ({effects}, data) => {
  return await effects.gql.mutations.saveVenture(data);
};

export const onChangePerPage = ({state},perPage) => {
  state.venture.venturePerPage = perPage;
}
export const onChangePage = ({state},page) => {
  state.venture.activePage = page;
}