import {createRouter, createWebHashHistory} from 'vue-router'
import * as actions from "@/store/actions";
import * as effects from '@/store/effects'
import * as state from '@/store/state'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: () => import('@/pages/Home'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/settings',
    name: 'Settings',
    component: () => import('@/pages/Settings'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/target-customers',
    name: 'Target Customers',
    component: () => import('@/pages/TargetCustomers'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/target-customers/detail',
    name: 'Target Customers Detail',
    component: () => import('@/pages/TargetCustomerDetail'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/ventures',
    name: 'Idea and Ventures',
    component: () => import('@/pages/Ventures'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/venture-detail/:id',
    name: 'Venture Detail',
    component: () => import('@/pages/VentureDetail'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/venture-team',
    name: 'Venture Team',
    component: () => import('@/pages/VentureTeam'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/ecosystem',
    name: 'Ecosystem',
    component: () => import('@/pages/Ecosystem'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/venture-settings',
    name: 'Venture Settings',
    component: () => import('@/pages/VentureSettings'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/contacts',
    name: 'Contacts',
    component: () => import('@/pages/Contacts'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/opportunities',
    name: 'Opportunities',
    component: () => import('@/pages/Opportunities'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/survey-data',
    name: 'Survey Data',
    component: () => import('@/pages/SurveyData'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/resources',
    name: 'Resources',
    component: () => import('@/pages/Resources'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import('@/pages/Register')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('@/pages/Login')
  },
  {
    path: '/reset-password',
    name: 'Reset Password',
    component: () => import('@/pages/ResetPassword')
  },
  {
    path: '/reset-password-email-sent',
    name: 'Reset Password Success',
    component: () => import('@/pages/ResetPasswordSuccess')
  },
  {
    path: '/reset-password/:token/:email',
    name: 'New Password',
    component: () => import('@/pages/NewPassword'),
  },
  {
    path: '/verify-email/:token/:email',
    name: 'Verify Email',
    component: () => import('@/pages/VerifyEmail'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/verify-email-step',
    name: 'Verify Email Step',
    component: () => import('@/pages/VerifyEmailStep'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/demographic-information',
    name: 'Demographic Information',
    component: () => import('@/pages/DemographicInformation'),
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/interest-activity',
    name: 'Interest And Activity',
    component: () => import('@/pages/InterestAndActivity'),
    meta: {
      requiresAuth: true,
    }
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

window.popStateDetected = false
window.addEventListener('popstate', () => {
  window.popStateDetected = true;
})

router.beforeEach(async (to, from, next) => {
  const IsItABackButton = window.popStateDetected
  window.popStateDetected = false
  const derivedState = (from && from.matched && from.matched[0] && from.matched[0]['instances'] && from.matched[0]['instances']['default']) ? from.matched[0]['instances']['default']['state'] : state;
  console.log(to, from , )
  const homeRoute = 'Dashboard';
  const loginRoute = 'Login';
  if (to.matched.some(routeRecord => routeRecord.meta.requiresAuth)) {
    if (!derivedState.currentUser?.id) {
      const {token, userId} = await actions.getStoredAuthToken();
      await actions.loginWithToken({state, effects}, {token, userId});
      // await actions.setStoredAuthToken();
    }
    if (!derivedState.isLoggedIn) {
      next({name: loginRoute})
    } else {
      // if (!derivedState.currentUser?.gender) {
      //   next({name: 'Demographic Information'});
      //   return false;
      // }
      // if (derivedState.currentUser?.tags?.filter(t => t?.type === 'INTEREST_ACTIVITY')?.length === 0) {
      //   next({name: 'Interest And Activity'});
      //   return false;
      // }
      next()
    }
  } else {
    if (!derivedState.isLoggedIn) {
      const {token, userId} = await actions.getStoredAuthToken();
      await actions.loginWithToken({state, effects}, {token, userId});
    }
    if (derivedState.isLoggedIn) {
      const derivedActions = (from && from.matched && from.matched[0] && from.matched[0]['instances'] && from.matched[0]['instances']['default']) && from.matched[0]['instances']['default']['actions']
      if (derivedActions && IsItABackButton) {
        window.history.go(-1)
        derivedActions.alert.showError({message: 'You\'re already logged in. You should logout first!'})
      } else {
        next({name: homeRoute});
      }
    } else next();
  }
});

export default router
