<template>
  <div class="flex flex-col bg-gray-50 md:flex-row max-h-screen">
    <div class="h-full">
      <SideNavigationBar :title="sideNavigationBar.title" :user="sideNavigationBar.user" :logo="sideNavigationBar.logo"
                         :navigation="sideNavigationBar.navigation" :navSelectedItem="sideNavigationBar.navSelectedItem"
                         :navIconColor="sideNavigationBar.navIconColor" :navBgColor="sideNavigationBar.navBgColor"
                         :navTextColor="sideNavigationBar.navTextColor"
                         :navTextSelectionColor="sideNavigationBar.navTextSelectionColor"
                         :navIconSelectionColor="sideNavigationBar.navIconSelectionColor"
                         :navHoverBg="sideNavigationBar.navHoverBg"
                         :navHoverBgOpacity="sideNavigationBar.navHoverBgOpacity"
                         :navSelectionBgColor="sideNavigationBar.navSelectionBgColor"
                         :navHoverTextColor="sideNavigationBar.navHoverTextColor"
                         :profileTopBorderColor="sideNavigationBar.profileTopBorderColor"
                         :profileTitleTextColor="sideNavigationBar.profileTitleTextColor"
                         :profileSubtitleTextColor="sideNavigationBar.profileSubtitleTextColor"
                         :profileSectionCustomClass="sideNavigationBar.profileSectionCustomClass"
                         :badgeColor="sideNavigationBar.badgeColor"
                         :badgeSelectionColor="sideNavigationBar.badgeSelectionColor"/>
    </div>
    <div class="w-full h-screen overflow-y-auto md:ml-80 px-10 py-4">
			<Header v-bind="content.profile"/>
			<div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
				<div class="col-span-2">
					<Text size="xl" weight="semibold" color="gray-900" :content="'Details'"/>
				</div>
				<div class="col-span-5">
					<div class="shadow-lg h-fit rounded-md">
						<div class="px-5 py-8 grid grid-cols-1 gap-4 sm:grid-cols-2 bg-white border-t border-l border-r border-gray-300 rounded-t-md">
							<Input v-for="key in Object.keys(content.details)" :key="key" :label="content.details[key].label" v-model:value="content.details[key].value" :type="content.details[key].type"/>
						</div>
						<div class="flex flex-row justify-between w-full bg-gray-100 border border-gray-300 rounded-b-none sm:rounded-b-md">
							<div/>
							<div class="flex flex-row items-end justify-end w-1/4 my-4 mr-4 sm:mr-3 lg:mr-5">
								<Button
										content="Cancel"
										:variant="null"
										customClass="bg-white text-black underline px-2 bg-gray-100"
								/>
								<Button
										content="Save"
										variant="primary"
										customClass="ml-4 px-4"
										:isLoading="isLoading"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
				<div class="col-span-2">
					<Text size="xl" weight="semibold" color="gray-900" :content="'Contact Info'"/>
				</div>
				<div class="col-span-5">
					<div class="h-fit rounded-md">
						<div class="bg-white border-t border-b border-gray-300 divide-y divide-gray-300">
							<div v-for="contact in content.contacts" :key="contact.type" class="grid grid-cols-4 py-3 px-2">
								<div class="col-span-2">
									<Text size="sm" weight="semibold" color="black" :content="contact.value"/>
								</div>
								<div class="col-span-1">
									<Badge :label="contact.type" :icon-name="contact.icon" isLeft bg-color="gray-500" is-rounded px="3" py="1" text-color="white"/>
								</div>
								<div class="col-span-1 flex justify-end">
									<a class="px-1">
										<Icon name="PencilAltIcon" color="primary-600" class="hover:text-primary-900"/>
									</a>
									<a class="px-1">
										<Icon name="TrashIcon" color="red-600" class="hover:text-red-900"/>
									</a>
								</div>
							</div>
						</div>
						<Text size="sm" color="primary-600" content="+ Add" decoration="underline" custom-class="cursor-pointer mt-2"/>
					</div>
				</div>
			</div>
			<div class="grid grid-cols-1 lg:grid-cols-7 gap-x-7 border-b py-10">
				<div class="col-span-2">
					<Text size="xl" weight="semibold" color="gray-900" :content="'Demographics'"/>
				</div>
				<div class="col-span-5">
					<div class="shadow-lg h-fit rounded-md">
						<div class="px-5 py-8 grid grid-cols-1 gap-4 sm:grid-cols-2 bg-white border-t border-l border-r border-gray-300 rounded-t-md">
							<Input v-for="key in Object.keys(content.demographics)" :key="key" :label="content.demographics[key].label" v-model:value="content.demographics[key].value" :type="content.demographics[key].type"/>
						</div>
						<div class="flex flex-row justify-between w-full bg-gray-100 border border-gray-300 rounded-b-none sm:rounded-b-md">
							<div/>
							<div class="flex flex-row items-end justify-end w-1/4 my-4 mr-4 sm:mr-3 lg:mr-5">
								<Button
										content="Cancel"
										:variant="null"
										customClass="bg-white text-black underline px-2 bg-gray-100"
								/>
								<Button
										content="Save"
										variant="primary"
										customClass="ml-4 px-4"
										:isLoading="isLoading"
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import SideNavigationBar from '../../organisms/SideNavigationBar/SideNavigationBar.vue'
import Header from '../../molecules/PageViewHeader/Profile/Profile'
import Text from '../../atoms/Text/Text'
import Input from '../../molecules/Inputs/Component/Component'
import Button from '../../atoms/Button/Button'
import Badge from '../../molecules/Badge/Basic/Basic'
import Icon from '../../atoms/Icons/Icons'
/**
 * - Use it to show a settings view with sidebar
 */
export default {
  components: {
    SideNavigationBar,
		Header,
		Text,
		Input,
		Button,
		Badge,
		Icon,
  },
  props: {
    sideNavigationBar: {
      type: Object,
      default: () => {
      }
    },
    content: {
      type: Object,
      default: () => {
      }
    }
  },
  setup(props, {emit}) {
    const selected = computed({
      get() {
        return props.navSelectedItem;
      },
      set(newValue) {
        emit("update:navSelectedItem", newValue);
      },
    });
    return {
      selected,
    };
  }
};
</script>
