import gql from 'graphql-tag';
import {schoolFragment} from "@/store/effects/gql/school/fragments";

export const PaymentMethod = `
{
  id type expirationDate cardType last4 isValid isDefault createdAt methodType isValid image
  vendors { name token callbackUrl redirectUrl isValid checkoutUrl }
}
`;
/*
*
*/
export const userFragment = gql`{
 id
  balance
  bio
  appVersion
  needToUpgrade
  timezoneOffset
  chatId
  playerId
  gender
  timezone
  firstName
  middleName
  lastName
  fullName
  dateOfBirth
  phones{id number}
  company{id name}
  email
  emails{id address verified}
  race
  tags{id name type}
  avatar
  createdAt
  updatedAt
  site { id name address address2 city state postalCode country phones{id number} googlePlacesId gps{lat lon} }
  sites { id name address address2 city state postalCode country phones{id number} googlePlacesId gps{lat lon}}
  paymentMethods${PaymentMethod}
  groups { id name }
  metadata
}`;

/*
*
*/
export const AuthPayLoad = gql`
{
  user${userFragment}
  verificationCode
}
`;


