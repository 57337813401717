import gql from 'graphql-tag';

export const targetCustomerFragment = gql`{
  id
  status 
  type
  tags {
      id
      name
      description
      status
      type
      category
  }
  # targetCustomerUsers {
  #   id
  #   name
  # }
}`
