import gql from 'graphql-tag';
import {userFragment} from '@/store/effects/gql/user/fragments';
import {companyFragment} from '@/store/effects/gql/company/fragments';

export const ventureFragment = gql`{
  id
  name
  blurb
  creator${userFragment}
  users${userFragment}
  profiles{id status}
  status
  opportunities {id}
  targetCustomerUser {
    id
    targetCustomer {
      id tags { id name type category }
    }
  }
  company${companyFragment}
  mode
}`
