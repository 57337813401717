module.exports = {
  Settings: {
    appName: 'DSCVRY',
    appVersion: '1.0',
    mainDomain: 'staging.dscvry.co',
    groupDomain: 'staging-dscvry-com',
    siteId: '',
    gqlServer: {
      url: 'https://api-staging.dscvry.co/',
      // url: 'http://localhost:4001'
    },
    mailerServer: {
      url: 'https://mailer.dscvry.co'
    },
    flow: 'modal',
    sentry: {
      dsn: ''
    },
    intercomAppId: '',
    appKey: "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHAiOiJDaGV3Ym94IiwidXNlcklkIjoiNjIzMTAxYWNhZGJlMWQwMDA4YTM5OWFmIiwiaWF0IjoxNjUxMDQ4NTUyfQ.1SdS4XkT-HQp7jdx6O4PMJt2Gh9brSRtFblPsoBLVsY",
    primary: '#0000FF',
    logo: {
      dark: {
      },
      light: {
        mobile: '',
        desktop: ''
      }
    },
    nextbillion: {
      api_key: '',
      api_host: ''
    },
    aws: {
      bucket: "dscvry-staging-media",
      region: "us-east-1",
      accessKeyId: "AKIA5PSQCDODAMNW6ROA",
      secretAccessKey: "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"
    },
    googleMapKey: 'AIzaSyBgDdvEYCqfsApDl5sQMlGqxfs-VJDhsmc'
  }
};
