import { createApp } from 'vue';
import App from './App.vue'
// import { registerSharedComponents } from '@/plugins/register'
import { OvermindPlugin } from "@/store";
// import * as Sentry from '@sentry/vue';
// import { Integrations } from "@sentry/tracing";
// import { Settings } from '../settings';
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'
import VueGoogleMaps from '@fawmi/vue-google-maps'
// import LoomTheme from './components/themes/default';

import router from './router'
// CSS
import './main.css'
import VCalendar from 'v-calendar';
import VueSocialSharing from "vue-social-sharing";

//
/** Globally register layout component */
import Default from './layouts/Default';
import Settings from "@/components/templates/Settings/Settings";

import 'animate.css'

// registerSharedComponents();

// Sentry.init({
//   dsn: Settings.sentry,
//   integrations: [new Integrations.BrowserTracing()],
//   tracesSampleRate: 1.0
// })

createApp(App)
  .use(router)
  .use(OvermindPlugin)
  .use(Vue3VideoPlayer)
  .use(VCalendar)
  .use(VueSocialSharing, {
    networks: {
      instagram: 'https://www.instagram.com',
      youtube: 'https://www.youtube.com',
      tiktok: 'https://www.tiktok.com'
    }
  })
  .use(VueGoogleMaps, {
    load: {
      key: Settings.googleMapKey,
      libraries: 'places'
    }
  })
  // .use(VueTailwind, { theme: LoomTheme })
  .component('default-layout', Default)
  .mount('#app')
