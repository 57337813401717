export const avatarSizes = {
  6: 'xs',
  8: 'sm',
  10: 'medium',
  12: 'lg',
  14: 'xl'
}

export const c = {
  register: {
    apiCalls: [
      {
        name: 'mutation createAppUser',
        description: 'use this mutation to create new user account',
        params: {
          email: '${email}',
          password: '${password}',
          sendEmailVerification: true,
        }
      }
    ],
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      alt: "Your Company"
    },
    logo: {
      name: "DSCVRY",
      // alt: "Your Company",
      // src: "https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600",
      // width: "auto",
      // height: "12",
      // customClass: "mx-auto",
    },
    inputs: {
      email: {
        type: "text",
        label: "Email address",
        placeholder: "your@email.com",
        rounded: true,
        value: null,
      },
      password: {
        type: "password",
        label: "Password",
        placeholder: "********",
        rounded: true,
        value: null,
      },
    },
    button: {
      label: "Next",
      customClass: "bg-green-500"
    },
    footerTitle: "Or continue with",
    icons: [
      {
        name: "PhoneIcon",
        size: 6,
        color: "gray-500"
      },
      {
        name: "PresentationChartBarIcon",
        size: 6,
        color: "gray-500"
      },
      {
        name: "AcademicCapIcon",
        size: 6,
        color: "gray-500"
      },
    ],
    terms: {
      title: "DSCVRY Terms And Conditions",
      content: ["Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id neque aliquam vestibulum morbi blandit cursus risus at ultrices. In cursus turpis massa tincidunt dui ut ornare lectus.", "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id neque aliquam vestibulum morbi blandit cursus risus at ultrices. In cursus turpis massa tincidunt dui ut ornare lectus.", "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id neque aliquam vestibulum morbi blandit cursus risus at ultrices. In cursus turpis massa tincidunt dui ut ornare lectus.","Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Id neque aliquam vestibulum morbi blandit cursus risus at ultrices. In cursus turpis massa tincidunt dui ut ornare lectus."],
      btnText: 'Close',
    }
  },
  login: {
    apiCalls: [
      {
        name: 'mutation login',
        description: 'use this mutation to login',
        params: {
          email: '${email}',
          password: '${password}'
        }
      }
    ],
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      //"https://www.suburbandiagnostics.com/Content/assets/images/login-bg-img@2x.png",
      alt: "Your Company"
    },
    logo: {
      name: "DSCVRY",
      // alt: "Your Company",
      // src: "https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600",
      // width: "auto",
      // height: "12",
      // customClass: "mx-auto",
    },
    inputs: {
      email: {
        type: "text",
        label: "Email address",
        placeholder: "your@email.com",
        rounded: true,
        value: null,
      },
      password: {
        type: "password",
        label: "Password",
        placeholder: "********",
        rounded: true,
        value: null,
      },
    },
    button: {
      label: "Log In",
      customClass: "bg-green-500"
    },
    footerTitle: "Or continue with",
    icons: [
      {
        name: "PhoneIcon",
        size: 6,
        color: "gray-500"
      },
      {
        name: "PresentationChartBarIcon",
        size: 6,
        color: "gray-500"
      },
      {
        name: "AcademicCapIcon",
        size: 6,
        color: "gray-500"
      },
    ]
  },
  resetPassword: {
    apiCalls: [
      {
        name: 'mutation passwordReset',
        description: 'use this mutation to send email for resetting password',
        params: {
          email: '${email}',
        }
      }
    ],
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      alt: "Your Company"
    },
    logo: {
      name: "DSCVRY",
      // alt: "Your Company",
      // src: "https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600",
      // width: "auto",
      // height: "12",
      // customClass: "mx-auto",
    },
    inputs: {
      email: {
        type: "text",
        label: "Email address",
        placeholder: "your@email.com",
        rounded: true,
        value: null,
      },
    },
    button: {
      label: "Next",
      customClass: "bg-green-500"
    },
  },
  newPassword: {
    apiCalls: [
      {
        name: 'mutation passwordReset',
        description: 'use this mutation to send email for resetting password',
        params: {
          email: '${email}',
        }
      }
    ],
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      alt: "Your Company"
    },
    logo: {
      name: "DSCVRY",
      // alt: "Your Company",
      // src: "https://tailwindui.com/img/logos/mark.svg?color=indigo&shade=600",
      // width: "auto",
      // height: "12",
      // customClass: "mx-auto",
    },
    inputs: {
      password: {
        type: "password",
        label: "Password",
        placeholder: "********",
        rounded: true,
        value: null,
      },
    },
    button: {
      label: "Reset",
      customClass: "bg-green-500"
    },
  },
  resetPasswordSuccess: {
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      alt: "Your Company"
    },
    logo: {
      name: "DSCVRY",
    },
  },
  verifyEmailStep: {
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      alt: "Your Company"
    },
    logo: {
      name: "DSCVRY",
    },
  },
  sideNavigationBar: {
    title: "DSCVRY",
    user: {
      avatar: 'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
      name: 'Tom Cook',
      role: 'View profile'
    },
    logo: {
      isShow: false,
      src: "",
      title: 'Workflow'
    },
    // navigation: [
    //   {id:1,name: 'Dashboard', count: '42',icon: 'HomeIcon', href: '/'},
    //   {id:2,name: 'Target Customers',count: '4', icon: 'BookOpenIcon', href: '/target-customers'},
    //   {id:3,name: 'Contacts', icon: 'UsersIcon', href: '/contacts'},
    //   {id:4,name: 'Opportunities', count: '12',icon: 'ExclamationIcon', href: '/opportunities'},
    //   {id:5,name: 'Survey Data', icon: 'MenuIcon', href: '/survey-data'},
    //   {id:6,name: 'Resources', count: '7', icon: 'LinkIcon', href: '/resources'},
    //   {id:7,name: 'Settings', icon: 'CogIcon', href: '/settings'},
    // ],
    navigation: [
      {id:1,name: 'Home',icon: 'HomeIcon', href: '/'},
      {id:2,name: 'Ideas / Ventures',count: '4', icon: 'BookOpenIcon', href: '/target-customers'},
      {id:3,name: '', icon: 'GroupIcon', href: '/contacts'},
      {id:4,name: 'Resources', count: '7', icon: 'LinkIcon', href: '/resources'},
    ],
    navSelectedItem: { id: 3, name: 'Projects', icon: 'FolderIcon', href: '#' },
    navIconColor: 'indigo-300',
    navBgColor: 'bg_primary',
    navTextColor: 'indigo-100',
    navTextSelectionColor: 'white',
    navIconSelectionColor: 'indigo-300',
    navHoverBg: 'indigo-600',
    navHoverBgOpacity: 'opacity-75',
    navSelectionBgColor: 'indigo-800',
    navBadgeColor:'indigo-800',
    navBadgeSelectionColor: 'indigo-600',
    profileTopBorderColor: 'indigo-200',
    profileTitleTextColor: 'white',
    profileSubtitleTextColor: 'indigo-50',
    badgeSelectionColor: 'indigo-600',
    badgeColor: 'indigo-500'
  },
  content: {
    viewName: 'targetCustomers',
    pageTotalRecords: 20,
    recordPerPage: 10,
    recordActivePage: 1,
    pagination: {
      totalRecords: 97,
      currentPage: 1,
      perPage: 10,
    },
    addItem: {
      title: 'Add a new target customer',
      content: 'Target Customers are large groups of people that might need your help, like "adults in physical therapy" or "millenial moms"',
    },
    items: [
      {
        title: "3. Heal as quickly as possible",
        actionLabel: "Motivator",
        subtitles: [
          {
            icon: "MapIcon",
            name: "Adults in physical therapy",
          },
          {
            icon: "LinkIcon",
            name: "Linked Opps",
            count: "7"
          },
          {
            icon: "ChatIcon",
            name: "Interview",
            count: "4"
          },
        ],
        customClass: ""
      },
      {
        title: "3. Heal as quickly as possible",
        actionLabel: "Motivator",
        subtitles: [
          {
            icon: "MapIcon",
            name: "Adults in physical therapy",
          },
          {
            icon: "LinkIcon",
            name: "Linked Opps",
            count: "7"
          },
          {
            icon: "ChatIcon",
            name: "Interview",
            count: "4"
          },
        ],
        customClass: ""
      }
    ],

    listHeader: {
      title: 'Target Customers',
      btnText: 'Add Customer',
      count: 2,
      searchbox: {
        placeholder: "Find a Contact",
        icon: "SearchIcon"
      },
      filter: {
        items: [
          { id: 1, name: 'Filter' },
          { id: 2, name: 'Arlene Mccoy' },
          { id: 3, name: 'Devon Webb' },
          { id: 4, name: 'Tom Cook' },
          { id: 5, name: 'Tanya Fox' },
        ],
        selectedItem: { id: 5, name: 'Filter' },
      },
      sort: {
        items: [
          { id: 1, name: 'Sort' },
          { id: 2, name: 'Arlene Mccoy' },
          { id: 3, name: 'Devon Webb' },
          { id: 4, name: 'Tom Cook' },
          { id: 5, name: 'Tanya Fox' },
        ],
        selectedItem: { id: 5, name: 'Sort' },
      },
      links: {
        leftLinks: [
          {
            content: "View Interview Schedule",
            decoration: "underline"
          }
        ],
        rightLinks: [
          {
            content: "Import Contacts",
            decoration: "underline"
          },
          {
            content: "Export as CSV",
            decoration: "underline"
          },
        ]
      }
    }
  },
  opportunities: {
    pagination: {
      totalRecords: 97,
      currentPage: 1,
      perPage: 10,
    },
    addItem: {
      title: 'Add a new target customer',
      content: 'Target Customers are large groups of people that might need your help, like "adults in physical therapy" or "millenial moms"',
    },
    items: [
      {
        title: "3. Heal as quickly as possible",
        actionLabel: "Motivator",
        subtitles: [
          {
            icon: "MapIcon",
            name: "Adults in physical therapy",
          },
          {
            icon: "LinkIcon",
            name: "Linked Opps",
            count: "7"
          },
          {
            icon: "ChatIcon",
            name: "Interview",
            count: "4"
          },
        ],
        customClass: ""
      },
      {
        title: "3. Heal as quickly as possible",
        actionLabel: "Motivator",
        subtitles: [
          {
            icon: "MapIcon",
            name: "Adults in physical therapy",
          },
          {
            icon: "LinkIcon",
            name: "Linked Opps",
            count: "7"
          },
          {
            icon: "ChatIcon",
            name: "Interview",
            count: "4"
          },
        ],
        customClass: ""
      }
    ],

    listHeader: {
      title: 'Opportunites',
      count: 2,
      searchbox: {
        placeholder: "Search",
        icon: "SearchIcon"
      },
      filter: {
        items: [
          { id: 1, name: 'Filter' },
          { id: 2, name: 'Arlene Mccoy' },
          { id: 3, name: 'Devon Webb' },
          { id: 4, name: 'Tom Cook' },
          { id: 5, name: 'Tanya Fox' },
        ],
        selectedItem: { id: 5, name: 'Filter' },
      },
      sort: {
        items: [
          { id: 1, name: 'Sort' },
          { id: 2, name: 'Arlene Mccoy' },
          { id: 3, name: 'Devon Webb' },
          { id: 4, name: 'Tom Cook' },
          { id: 5, name: 'Tanya Fox' },
        ],
        selectedItem: { id: 5, name: 'Sort' },
      },
      links: {
        leftLinks: [
          {
            content: "View Interview Schedule",
            decoration: "underline"
          }
        ],
        rightLinks: [
          {
            content: "Import Contacts",
            decoration: "underline"
          },
          {
            content: "Export as CSV",
            decoration: "underline"
          },
        ]
      }
    }
  },
  detailViewHeader: {
    title: "#13 Keep track of what exercises to do",
    options: [
      "SVG",
      "PDF",
      "PNG"
    ],
    backUrl: "",
    backLabel: "Target Customers",
    actionUrl: "",
    actionLabel: "",
    isActive: true,
  },
  subNavigationBar: [
    { name: 'Details', count: null, href: '#', icon: 'PencilIcon', current: false },
    { name: 'Interviews', count: 13, href: '#', icon: 'RefreshIcon', current: false },
    { name: 'Surveys', count: 9, href: '#', icon: 'ServerIcon', current: true },
  ],
  formFieldGroup: [
    {
      header: 'Target Customer Details',
      description: 'Description Text about this section',
      isForm: true,
      name: {
        value: '',
        label: 'Name',
      },
      hideText: 'Hide',
      estimatedMarketSize: {
        value: '',
        label: 'Estimated Market Size',
      },
      researchSource: {
        value: '',
        label: 'Research Source',
      },
      notes: {
        value: '',
        label: 'Notes',
      },
      types: {
        list: [
          { id: 1, name: 'Wade Cooper' },
          { id: 2, name: 'Arlene Mccoy' },
          { id: 3, name: 'Devon Webb' },
          { id: 4, name: 'Tom Cook' },
          { id: 5, name: 'Tanya Fox' },
        ],
        selected: { id: 5, name: 'Tanya Fox' },
        label: 'Type',
      },
      demographics: {
        list: [
          { id: 1, name: 'Wade Cooper' },
          { id: 2, name: 'Arlene Mccoy' },
          { id: 3, name: 'Devon Webb' },
          { id: 4, name: 'Tom Cook' },
          { id: 5, name: 'Tanya Fox' },
        ],
        selected: { id: 5, name: 'Tanya Fox' },
        label: 'Demographics',
      },
      interestAndActivities: {
        list: [
          { id: 1, name: 'Wade Cooper' },
          { id: 2, name: 'Arlene Mccoy' },
          { id: 3, name: 'Devon Webb' },
          { id: 4, name: 'Tom Cook' },
          { id: 5, name: 'Tanya Fox' },
        ],
        selected: { id: 5, name: 'Tanya Fox' },
        label: 'Interest and Activities',
      },
      markets: {
        list: [
          { id: 1, name: 'Wade Cooper' },
          { id: 2, name: 'Arlene Mccoy' },
          { id: 3, name: 'Devon Webb' },
          { id: 4, name: 'Tom Cook' },
          { id: 5, name: 'Tanya Fox' },
        ],
        selected: { id: 5, name: 'Tanya Fox' },
        label: 'Market',
      },
      open: false,
      iconText: 'View Map',
      iconName: 'MapIcon',
      displayTableOnly: true,
      displayHeaders: false,
      detailHidden: false,
      textHtmlMobile: `
            <div>
            <div class="mt-2">
            <b>Name: </b>  &nbsp;Should be good balance between simple and description.
            </div>
            <div class="mt-2">
                <b>Type: </b>  &nbsp;Individuals make this a B2C venture. Employees, businesses, schools, or governments make this a B2B or B2G venture.
            </div>
            <div class="mt-2">
                <b>Demographics: </b>  &nbsp;Combines any age, income-level, gender, or ethnicity tags you selected when creating this target customer.
            </div>
            <div class="mt-2">
                <b>Interests and Activities: </b>  &nbsp;Tags that specify what they might like (interests) or things they might do (activities).
            </div>
            <div class="mt-2">
                <b>Market: </b>  &nbsp;The geographical boundary for this group. Defaults to the United States.
            </div>
            <div class="mt-2">
                <b>Estimated Market Size: </b>  &nbsp;Based on your research, and how many people are in this Target Customer Group. This is required to later calculate things like "adjusted market size."
            </div>
            <div class="mt-2">
                <b>Research Source: </b>  &nbsp;Add a url or other citation information when people ask how you calculated your Estimated and Adjusted market size.
            </div>
            <div class="mt-2 mb-8">
                <b>Note: </b>  &nbsp;Should be good balance between simple and description.
            </div>
            </div>`,
      textHtml: `
                <div class="text-grayhelptext">
                  <div class="mt-2">
                    Name:  &nbsp;Should be good balance between simple and description.
                  </div>
                  <div class="mt-2">
                    Type: &nbsp;Individuals make this a B2C venture. Employees, businesses, schools, or governments make this a B2B or B2G venture.
                  </div>
                  <div class="mt-2">
                    Demographics:  &nbsp;Combines any age, income-level, gender, or ethnicity tags you selected when creating this target customer.
                  </div>
                  <div class="mt-2">
                    Interests and Activities:  &nbsp;Tags that specify what they might like (interests) or things they might do (activities).
                  </div>
                  <div class="mt-2">
                    Market:  &nbsp;The geographical boundary for this group. Defaults to the United States.
                  </div>
                  <div class="mt-2">
                    Estimated Market Size:  &nbsp;Based on your research, and how many people are in this Target Customer Group. This is required to later calculate things like "adjusted market size."
                  </div>
                  <div class="mt-2">
                    Research Source:  &nbsp;Add a url or other citation information when people ask how you calculated your Estimated and Adjusted market size.
                  </div>
                  <div class="mt-2 mb-8">
                    Note: &nbsp;Should be good balance between simple and description.
                  </div>
                </div>`,
    }
  ],
  home: {
    title: 'Welcome to DSCVRY!',
    description: 'Let\'s find the next big problem to solve',
    profile: {
      avatar: '',
      name: 'Test User'
    },
    empty: {
      title: 'Get started by adding your first idea!',
      description: 'Once your add an idea, it will show up here',
      btnText: '+ Add an Idea',
      action: () => {
      },
      icon: 'LightBulbIcon'
    },
    ideas: [
      {
        title: "Range",
        description: 'A platform to connect homeowners with professionals so that can figure out whether',
        actionLabel: "Motivator",
        subtitles: [
          {
            icon: "MapIcon",
            name: "Target Customers",
            count: '2'
          },
          {
            icon: "ExclamationIcon",
            name: "Opportunities",
            count: "7"
          },
          {
            icon: "UsersIcon",
            name: "Team",
            count: "2"
          },
        ],
        customClass: ""
      },
      {
        title: "Range",
        description: 'A platform to connect homeowners with professionals so that can figure out whether',
        actionLabel: "Motivator",
        subtitles: [
          {
            icon: "MapIcon",
            name: "Target Customers",
            count: '2'
          },
          {
            icon: "ExclamationIcon",
            name: "Opportunities",
            count: "7"
          },
          {
            icon: "UsersIcon",
            name: "Team",
            count: "2"
          },
        ],
        customClass: ""
      }
    ],
    ideaHeader: {
      title: 'Ideas and Ventures (7)',
      btnText: 'Add Idea'
    },
    resources: [
      {
        title: "Building Blocks 1: Learning and Adapting to Risk",
        imgSrc: "https://i.ibb.co/WtkFj23/unnamed-2.jpg",
        desc: "Setting up a mental model for how to look at product discovery",
        items: [
          {
            icon: "LinkIcon",
            label: "Article"
          },
          {
            label: "Getting Started"
          }
        ],
        customClass: ""
      },
      {
        title: "Building Blocks 1: Learning and Adapting to Risk",
        imgSrc: "https://i.ibb.co/WtkFj23/unnamed-2.jpg",
        desc: "Setting up a mental model for how to look at product discovery",
        items: [
          {
            icon: "LinkIcon",
            label: "Article"
          },
          {
            label: "Getting Started"
          }
        ],
        customClass: ""
      }
    ],
    ecosystems: [
      {
        title: "Startup Virginia",
        description: 'A mobile app to help people keep track of their physical therapy exercises at home',
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: '32'
          },
          {
            icon: "NewspaperIcon",
            name: "Programs",
            count: "2"
          },
          {
            icon: "UsersIcon",
            name: "Cohorts",
            count: "7"
          },
        ],
        customClass: ""
      },
      {
        title: "Startup Virginia",
        description: 'A mobile app to help people keep track of their physical therapy exercises at home',
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: '32'
          },
          {
            icon: "NewspaperIcon",
            name: "Programs",
            count: "2"
          },
          {
            icon: "UsersIcon",
            name: "Cohorts",
            count: "7"
          },
        ],
        customClass: ""
      },
    ],
  },
  ecosystem: {
    title: 'Welcome to DSCVRY!',
    description: 'Let\'s find the next big problem to solve',
    cards: [
      {
        icon: 'BulbIcon',
        category: 'Venture Teams',
        count: 35
      },
      {
        icon: 'UsersIcon',
        category: 'People',
        count: 92
      },
      {
        icon: 'ExclamationIcon',
        category: 'Opportunities',
        count: 149
      },
    ],
    cohorts: [
      {
        title: "Test",
        actionLabel: "Motivator",
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: '2'
          },
          {
            icon: "UsersIcon",
            name: "People",
            count: "10"
          },
          {
            icon: "ExclamationIcon",
            name: "Opportunities",
            count: "0"
          },
        ],
        customClass: ""
      },
      {
        title: "Test",
        actionLabel: "Motivator",
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: '2'
          },
          {
            icon: "UsersIcon",
            name: "People",
            count: "10"
          },
          {
            icon: "ExclamationIcon",
            name: "Opportunities",
            count: "0"
          },
        ],
        customClass: ""
      },

    ],
    programs: [
      {
        title: "The Idea Factory",
        actionLabel: "8 weeks",
        subtitles: [
          {
            icon: "CalendarIcon",
            name: "Cohorts",
            count: '2'
          },
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: "7"
          },
          {
            icon: "UsersIcon",
            name: "Team",
            count: "2"
          },
          {
            icon: "ExclamationIcon",
            name: "Opportunities",
            count: "0"
          },
        ],
        customClass: ""
      },
      {
        title: "The Idea Factory",
        actionLabel: "8 weeks",
        subtitles: [
          {
            icon: "CalendarIcon",
            name: "Cohorts",
            count: '2'
          },
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: "7"
          },
          {
            icon: "UsersIcon",
            name: "Team",
            count: "2"
          },
          {
            icon: "ExclamationIcon",
            name: "Opportunities",
            count: "0"
          },
        ],
        customClass: ""
      },
    ],
    resources: [
      {
        title: "Building Blocks 1: Learning and Adapting to Risk",
        imgSrc: "https://i.ibb.co/WtkFj23/unnamed-2.jpg",
        desc: "Setting up a mental model for how to look at product discovery",
        items: [
          {
            icon: "LinkIcon",
            label: "Article"
          },
          {
            label: "Getting Started"
          }
        ],
        customClass: ""
      },
      {
        title: "Building Blocks 1: Learning and Adapting to Risk",
        imgSrc: "https://i.ibb.co/WtkFj23/unnamed-2.jpg",
        desc: "Setting up a mental model for how to look at product discovery",
        items: [
          {
            icon: "LinkIcon",
            label: "Article"
          },
          {
            label: "Getting Started"
          }
        ],
        customClass: ""
      }
    ],
    ecosystems: [
      {
        title: "Startup Virginia",
        description: 'A mobile app to help people keep track of their physical therapy exercises at home',
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: '32'
          },
          {
            icon: "NewspaperIcon",
            name: "Programs",
            count: "2"
          },
          {
            icon: "UsersIcon",
            name: "Cohorts",
            count: "7"
          },
        ],
        customClass: ""
      },
      {
        title: "Startup Virginia",
        description: 'A mobile app to help people keep track of their physical therapy exercises at home',
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: '32'
          },
          {
            icon: "NewspaperIcon",
            name: "Programs",
            count: "2"
          },
          {
            icon: "UsersIcon",
            name: "Cohorts",
            count: "7"
          },
        ],
        customClass: ""
      },
    ],
  },
  range: {
    title: 'Range',
    description: 'A mobile physical therapy app to help track exercises at home',
    resources: [
      {
        title: "Building Blocks 1: Learning and Adapting to Risk",
        imgSrc: "https://i.ibb.co/WtkFj23/unnamed-2.jpg",
        desc: "Setting up a mental model for how to look at product discovery",
        items: [
          {
            icon: "LinkIcon",
            label: "Article"
          },
          {
            label: "Getting Started"
          }
        ],
        customClass: ""
      },
      {
        title: "Building Blocks 1: Learning and Adapting to Risk",
        imgSrc: "https://i.ibb.co/WtkFj23/unnamed-2.jpg",
        desc: "Setting up a mental model for how to look at product discovery",
        items: [
          {
            icon: "LinkIcon",
            label: "Article"
          },
          {
            label: "Getting Started"
          }
        ],
        customClass: ""
      }
    ],
    ecosystems: [
      {
        title: "Startup Virginia",
        description: 'A mobile app to help people keep track of their physical therapy exercises at home',
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: '32'
          },
          {
            icon: "NewspaperIcon",
            name: "Programs",
            count: "2"
          },
          {
            icon: "UsersIcon",
            name: "Cohorts",
            count: "7"
          },
        ],
        customClass: ""
      },
      {
        title: "Startup Virginia",
        description: 'A mobile app to help people keep track of their physical therapy exercises at home',
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: '32'
          },
          {
            icon: "NewspaperIcon",
            name: "Programs",
            count: "2"
          },
          {
            icon: "UsersIcon",
            name: "Cohorts",
            count: "7"
          },
        ],
        customClass: ""
      },
    ],
    empty: {
      title: 'Want to do this as an accelerator?',
      description: 'product discovery can be an overwhelming process, especially if it\'s your first time. Adding this venture to our accelerator for $99 will focus your experience around exactly what you need to do next, give you extra help content, and also access to your private Slack community.',
      btnText: 'Get Started',
      action: () => {},
      icon: 'BoltIcon'
    },
    research: {
      title: 'Research Progress',
      badge: 'Getting Started',
      description: 'The end goal is generate actionable opportunity data directly from your potential customers. To do this, you generally need at least 100 contacts to send a survey to. 12 interviews to identify the most prevalent opportunities to add to your survey. and at least 30 survey responses.',
      items: [
        {
          'Target Customer': 'Adults in physical theraphy',
          'Opportunites': 0,
          'Contacts': '94 / 100',
          'Scheduled Interviews': 4,
          'Completed Interviews': '4 / 12',
          'Survey Responses': '20 / 30'
        }
      ]
    },
    venture: {
      title: 'Your Venture Team',
      btnText: 'settings',
      members: [
        {
          name: 'L',
          role: 'Owner',
          contacts: 0,
          interviews: 0,
          Opportunites: 0
        },
        {
          name: 'G',
          role: 'Member',
          contacts: 0,
          interviews: 0,
          Opportunites: 0
        }
      ],
      inviteBtnText: 'Invite someone'
    }
  },
  venture_team: {
    title: 'Range',
    description: 'A mobile physical therapy app to help track exercises at home',
    resources: [
      {
        title: "Building Blocks 1: Learning and Adapting to Risk",
        imgSrc: "https://i.ibb.co/WtkFj23/unnamed-2.jpg",
        desc: "Setting up a mental model for how to look at product discovery",
        items: [
          {
            icon: "LinkIcon",
            label: "Article"
          },
          {
            label: "Getting Started"
          }
        ],
        customClass: ""
      },
      {
        title: "Building Blocks 1: Learning and Adapting to Risk",
        imgSrc: "https://i.ibb.co/WtkFj23/unnamed-2.jpg",
        desc: "Setting up a mental model for how to look at product discovery",
        items: [
          {
            icon: "LinkIcon",
            label: "Article"
          },
          {
            label: "Getting Started"
          }
        ],
        customClass: ""
      }
    ],
    ecosystems: [
      {
        title: "Startup Virginia",
        description: 'A mobile app to help people keep track of their physical therapy exercises at home',
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: '32'
          },
          {
            icon: "NewspaperIcon",
            name: "Programs",
            count: "2"
          },
          {
            icon: "UsersIcon",
            name: "Cohorts",
            count: "7"
          },
        ],
        customClass: ""
      },
      {
        title: "Startup Virginia",
        description: 'A mobile app to help people keep track of their physical therapy exercises at home',
        subtitles: [
          {
            icon: "LightBulbIcon",
            name: "Ventures",
            count: '32'
          },
          {
            icon: "NewspaperIcon",
            name: "Programs",
            count: "2"
          },
          {
            icon: "UsersIcon",
            name: "Cohorts",
            count: "7"
          },
        ],
        customClass: ""
      },
    ],
    empty: {
      title: 'How is collecting survey data going?',
      description: 'Looks like you are well on your way to getting opportunity data from your survey. Once you get at least 30 responses for a Target Customer we can walk you through how to intrepret the data and think about next steps. Keep it up!',
      badge: 'Collecting Survey Data',

      btnText: 'Managing survey responses',
      action: () => {},
      icon: 'MenuIcon'
    },
    research: {
      title: 'Research Progress',
      badge: 'Getting Started',
      description: 'The end goal is generate actionable opportunity data directly from your potential customers. To do this, you generally need at least 100 contacts to send a survey to. 12 interviews to identify the most prevalent opportunities to add to your survey. and at least 30 survey responses.',
      items: [
        {
          'Target Customer': 'Adults in physical theraphy',
          'Opportunites': 0,
          'Contacts': '94 / 100',
          'Scheduled Interviews': 4,
          'Completed Interviews': '4 / 12',
          'Survey Responses': '20 / 30'
        }
      ]
    },
    opportunity: {
      title: 'Opportunity Data',
      description: 'Now that you have real data back from potential customers, you can do an apples-to-apples comparison to figure out the most viable combination of target customer segments and the problems those people have.',
      items: [
        {
          'Opportunity': '#20 Do their exercises',
          'Target Customer': 'Adults in physical',
          'Responses': '29',
          'Magnitude': 27,
          'Market': '10.1M',
        }
      ]
    },
    venture: {
      title: 'Your Venture Team',
      btnText: 'settings',
      members: [
        {
          name: 'L',
          role: 'Owner',
          contacts: 0,
          interviews: 0,
          Opportunites: 0
        },
        {
          name: 'G',
          role: 'Member',
          contacts: 0,
          interviews: 0,
          Opportunites: 0
        }
      ],
      inviteBtnText: 'Invite someone'
    }
  },
  welcome: {
    title: "Welcome to DSCVRY - we're glad you're here!",
    textHtml: '<p>We are here to turn your big ideas into the fuel that powers the product discovery  process so you can build healthy businesses that solve big problems.</p>',
    videoTitle: 'DSCVRY in 30 seconds',
    videoUrl: '',
    noBtnText: 'Next',
  },
  inviteMember: {
    isShow: true,
    title: 'Invite someone to join your venture team!',
    content: 'Let others join and contribute to your venture.',
    isLoading: true,
    formGroup: [
        {
            type: 'email',
            label: 'Email Address',
            placeholder: 'Email Address',
        },
        {
            type: 'select',
            label: 'Role',
            placeholder: '',
            items: [
                {
                    id: 1,
                    name: 'Member (add/edit)'
                },
            ],
            selectedItem : {},
        },
    ],
    submitBtnText: 'Invite'
  },
  settings: {
    profile: {
      profile: {
        avatar: '',
        name: 'Test User',
        description: 'member since 4/1/2022'
      },
      options: [
        'Sign out'
      ],
      label: 'Options'
    },
    details: {
      firstName: {
        label: 'First Name',
        name: 'firstName',
        type: 'string',
        value: null,
      },
      lastName: {
        label: 'Last Name',
        name: 'lastName',
        type: 'string',
        value: null,
      },
      email: {
        label: 'Email',
        name: 'email',
        type: 'email',
        value: null,
      }
    },
    demographics: {
      age: {
        label: 'Age',
        name: 'age',
        type: 'string',
        value: null,
      },
      income: {
        label: 'Income',
        name: 'income',
        type: 'string',
        value: null,
      },
      gender: {
        label: 'Gender',
        name: 'gender',
        type: 'string',
        value: null,
      },
      location: {
        label: 'Location',
        name: 'location',
        type: 'string',
        value: null,
      }
    },
    contacts: [
      {
        type: 'Email',
        icon: 'MailIcon',
        value: ''
      },
      {
        type: 'Twitter',
        icon: 'MailIcon',
        value: '@lu'
      }
    ]
  },
  venture_settings: {
    profile: {
      profile: {
        avatar: '',
        name: 'Test User',
        description: 'member since 4/1/2022'
      },
      options: [
        'Sign out'
      ],
      label: 'Options'
    },
    info: {
      name: {
        label: 'Name',
        name: 'name',
        type: 'text',
        value: null,
      },
      idea: {
        label: 'Idea',
        name: 'idea',
        type: 'textarea',
        value: null,
      },
    },
    payments: [
      {
        type: 'Visa',
        last4: 2345,

      },{
        type: 'Visa',
        last4: 2345,

      }
    ],
    contacts: [
      {
        type: 'Email',
        icon: 'MailIcon',
        value: ''
      },
      {
        type: 'Twitter',
        icon: 'MailIcon',
        value: '@lu'
      }
    ],
    ecosystems: [
      {
        name: 'Startup Virginia',
        date: new Date()
      },
    ],
    programs: [
      {
        name: 'The Idea Factory',
        description: 'Summar 22 Cohort'
      }
    ]
  },
  validateEmail: {
    title: "Let's validate your email",
    description: 'Just click the button below or copy and paste the line back into your browser to validate your email address.',
    btnText: 'Click to Validate',
    link: 'https://app.dscvry.co/#/login'
  },
  addIdea: {
    isShow: true,
    title: 'Add a New Idea',
    content: 'Ideas are potential solutions to hypothesized problems. Let\'s walk through who needs your help and why.',
    isLoading: true,
  },
  demographics: {
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      alt: "Your Company"
    },
    items: [
      {
        label: 'About how old are you?',
        type: 'RADIO',
        options: [
          '12-17', '18-24', '25-34', '35-44', '45-54', '55-64', '65-74', '75+', 'Prefer not to say'
        ],
        value: null,
      },
      {
        label: 'How would you describe your gender',
        type: 'RADIO',
        options: [
          'Male', 'Female', 'Prefer not to say', 'Self describe'
        ],
        value: null,
        inputValue: null,
      },
      {
        label: 'With which racial and ethic group(s) do you identify?',
        type: 'CHECKBOX',
        options: [
          'American Indian or Alaskan Native',
          'Asian',
          'Black or African American',
          'Hispanic LatinX or Spanish Origin',
          'Middle Eastern or North African',
          'Native Hawaiian or Pacific Islander',
          'White',
          'Prefer not to say',
          'Self describe'
        ],
        value: [],
        inputValue: null
      },
      {
        label: 'In which city or state do you live in?',
        type: 'AUTOCOMPLETE',
        placeholder: 'Start typing',
        value: null
      },
      {
        label: 'What is your level of household income?',
        type: 'RADIO',
        options: [
          'Lower income (under $50k)',
          'Middle income ($50k - $150k)',
          'Higher income (over $150k)',
          "Prefer not to say"
        ],
        value: null,
      },

    ]
  },
  interests: {
    image: {
      src: require("../assets/images/login_page_sidepanel.png"),
      alt: "Your Company"
    },
  }
}
